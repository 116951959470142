body {
  background-color: #efefef;
}

.main {
  padding: 0.2rem;
}

.stepper {
  margin: 1rem 3rem 0 0;
}

.choose-font {
  margin-top: 1rem;
}

.input-container {
  background-color: #0c6099;
  padding: 1rem 1.5rem;
  border-radius: 8px;

  #input-text {
    background-color: white;
  }
}

// Font Card
.font-container {
  background-color: white;

  .is-selected {
    float: right;
  }
}

.font-card-container {
  padding: 1rem;
  margin: 0.75rem 0;
  overflow: hidden;

  .text-container-finish {
    max-height: 100px;
    max-width: 220px;
  }

  .text-container-finish,
  .text-container {
    margin: 0.5rem 0;
    font-size: 4rem;
    overflow-wrap: break-word;
    line-height: 1.2;
  }

  .details {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 1rem;
  }
}

.final-design-container {
  width: 20rem;
}

.pricing-table {
  max-width: 600px;

  .price-btn {
    min-width: 60px;
  }
}