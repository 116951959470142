@font-face {
  font-family: "Affectionately";
  src: local("Affectionately"),
  url("/fonts/Affectionately.otf") format("truetype");
}

@font-face {
  font-family: "Ananda";
  src: local("Ananda"),
  url("/fonts/Ananda.ttf") format("truetype");
}

@font-face {
  font-family: "Andalucia";
  src: local("Andalucia"),
  url("/fonts/Andalucia.ttf") format("truetype");
}

@font-face {
  font-family: "Anglena";
  src: local("Anglena"),
  url("/fonts/Anglena.otf") format("truetype");
}

@font-face {
  font-family: "Ashburton";
  src: local("Ashburton"),
  url("/fonts/Ashburton.otf") format("truetype");
}

@font-face {
  font-family: "AthenaoftheOcean";
  src: local("AthenaoftheOcean"),
  url("/fonts/AthenaoftheOcean.ttf") format("truetype");
}

@font-face {
  font-family: "BabyNames";
  src: local("BabyNames"),
  url("/fonts/BabyNames.otf") format("truetype");
}

@font-face {
  font-family: "BacktoBlack";
  src: local("BacktoBlack"),
  url("/fonts/BacktoBlack.ttf") format("truetype");
}

@font-face {
  font-family: "BeeKindMonoline";
  src: local("BeeKindMonoline"),
  url("/fonts/BeeKindMonoline.ttf") format("truetype");
}

@font-face {
  font-family: "BirdsofParadise";
  src: local("BirdsofParadise"),
  url("/fonts/BirdsofParadise.ttf") format("truetype");
}

@font-face {
  font-family: "Blacksword";
  src: local("Blacksword"),
  url("/fonts/Blacksword.otf") format("truetype");
}

@font-face {
  font-family: "BrockScript";
  src: local("BrockScript"),
  url("/fonts/BrockScript.ttf") format("truetype");
}

@font-face {
  font-family: "CalisshaSans";
  src: local("CalisshaSans"),
  url("/fonts/CalisshaSans.ttf") format("truetype");
}

@font-face {
  font-family: "CarloMelow";
  src: local("CarloMelow"),
  url("/fonts/CarloMelow.otf") format("truetype");
}

@font-face {
  font-family: "CarloMelowSans";
  src: local("CarloMelowSans"),
  url("/fonts/CarloMelowSans.otf") format("truetype");
}

@font-face {
  font-family: "ChalisaOctavia";
  src: local("ChalisaOctavia"),
  url("/fonts/ChalisaOctavia.otf") format("truetype");
}

@font-face {
  font-family: "Charline";
  src: local("Charline"),
  url("/fonts/Charline.otf") format("truetype");
}

@font-face {
  font-family: "Cherolina";
  src: local("Cherolina"),
  url("/fonts/Cherolina.ttf") format("truetype");
}

@font-face {
  font-family: "ChickenQuiche";
  src: local("ChickenQuiche"),
  url("/fonts/ChickenQuiche.ttf") format("truetype");
}

@font-face {
  font-family: "Chubby";
  src: local("Chubby"),
  url("/fonts/Chubby.otf") format("truetype");
}

@font-face {
  font-family: "Chunkring";
  src: local("Chunkring"),
  url("/fonts/Chunkring.ttf") format("truetype");
}

@font-face {
  font-family: "ColumbiaSerialRegular";
  src: local("ColumbiaSerialRegular"),
  url("/fonts/ColumbiaSerialRegular.ttf") format("truetype");
}

@font-face {
  font-family: "ComfortaaBold";
  src: local("ComfortaaBold"),
  url("/fonts/ComfortaaBold.ttf") format("truetype");
}

@font-face {
  font-family: "ComfortaaLight";
  src: local("ComfortaaLight"),
  url("/fonts/ComfortaaLight.ttf") format("truetype");
}

@font-face {
  font-family: "CreamCake";
  src: local("CreamCake"),
  url("/fonts/CreamCake.otf") format("truetype");
}

@font-face {
  font-family: "Crystallized";
  src: local("Crystallized"),
  url("/fonts/Crystallized.ttf") format("truetype");
}

@font-face {
  font-family: "Daddy";
  src: local("Daddy"),
  url("/fonts/Daddy.otf") format("truetype");
}

@font-face {
  font-family: "Daydreamer";
  src: local("Daydreamer"),
  url("/fonts/Daydreamer.ttf") format("truetype");
}

@font-face {
  font-family: "DecemberHolidays";
  src: local("DecemberHolidays"),
  url("/fonts/DecemberHolidays.ttf") format("truetype");
}

@font-face {
  font-family: "Delightful";
  src: local("Delightful"),
  url("/fonts/Delightful.ttf") format("truetype");
}

@font-face {
  font-family: "Energetica";
  src: local("Energetica"),
  url("/fonts/Energetica.ttf") format("truetype");
}
@font-face {
  font-family: "EngraversGothicRegular";
  src: local("EngraversGothicRegular"),
  url("/fonts/EngraversGothicRegular.otf") format("truetype");
}
@font-face {
  font-family: "Gardena";
  src: local("Gardena"),
  url("/fonts/Gardena.otf") format("truetype");
}
@font-face {
  font-family: "hellohoney";
  src: local("hellohoney"),
  url("/fonts/hellohoney.ttf") format("truetype");
}
@font-face {
  font-family: "heroesassemble2";
  src: local("heroesassemble2"),
  url("/fonts/heroesassemble2.ttf") format("truetype");
}
@font-face {
  font-family: "heroesassembleexpandital2";
  src: local("heroesassembleexpandital2"),
  url("/fonts/heroesassembleexpandital2.ttf") format("truetype");
}
@font-face {
  font-family: "Highbright";
  src: local("Highbright"),
  url("/fonts/Highbright.ttf") format("truetype");
}
@font-face {
  font-family: "ILoveGlitter";
  src: local("ILoveGlitter"),
  url("/fonts/ILoveGlitter.ttf") format("truetype");
}
@font-face {
  font-family: "Imperator";
  src: local("Imperator"),
  url("/fonts/Imperator.ttf") format("truetype");
}
@font-face {
  font-family: "ImprintMTShadow";
  src: local("ImprintMTShadow"),
  url("/fonts/ImprintMTShadow.ttf") format("truetype");
}
@font-face {
  font-family: "JosephSophia";
  src: local("JosephSophia"),
  url("/fonts/JosephSophia.ttf") format("truetype");
}
@font-face {
  font-family: "KarimaRegular";
  src: local("KarimaRegular"),
  url("/fonts/KarimaRegular.otf") format("truetype");
}
@font-face {
  font-family: "KissingLove";
  src: local("KissingLove"),
  url("/fonts/KissingLove.ttf") format("truetype");
}
@font-face {
  font-family: "Lactosa";
  src: local("Lactosa"),
  url("/fonts/Lactosa.otf") format("truetype");
}
@font-face {
  font-family: "LactosaExtra";
  src: local("LactosaExtra"),
  url("/fonts/LactosaExtra.otf") format("truetype");
}
@font-face {
  font-family: "LactosaSwash";
  src: local("LactosaSwash"),
  url("/fonts/LactosaSwash.otf") format("truetype");
}
@font-face {
  font-family: "LaughingandSmiling";
  src: local("LaughingandSmiling"),
  url("/fonts/LaughingandSmiling.ttf") format("truetype");
}
@font-face {
  font-family: "LaughingandSmiling";
  src: local("LaughingandSmiling"),
  url("/fonts/LaughingandSmiling.ttf") format("truetype");
}
@font-face {
  font-family: "LovelyHome";
  src: local("LovelyHome"),
  url("/fonts/LovelyHome.ttf") format("truetype");
}
@font-face {
  font-family: "LoveTwist";
  src: local("LoveTwist"),
  url("/fonts/LoveTwist.ttf") format("truetype");
}
@font-face {
  font-family: "Luna";
  src: local("Luna"),
  url("/fonts/Luna.ttf") format("truetype");
}
@font-face {
  font-family: "MadinaClean";
  src: local("MadinaClean"),
  url("/fonts/MadinaClean.ttf") format("truetype");
}
@font-face {
  font-family: "Mellody";
  src: local("Mellody"),
  url("/fonts/Mellody.otf") format("truetype");
}
@font-face {
  font-family: "Mellodyan";
  src: local("Mellodyan"),
  url("/fonts/Mellodyan.ttf") format("truetype");
}
@font-face {
  font-family: "MyLovelyDemo";
  src: local("MyLovelyDemo"),
  url("/fonts/MyLovelyDemo.ttf") format("truetype");
}
@font-face {
  font-family: "Nathalia";
  src: local("Nathalia"),
  url("/fonts/Nathalia.ttf") format("truetype");
}
@font-face {
  font-family: "OctoberTwilight";
  src: local("OctoberTwilight"),
  url("/fonts/OctoberTwilight.ttf") format("truetype");
}
@font-face {
  font-family: "Olivia";
  src: local("Olivia"),
  url("/fonts/Olivia.otf") format("truetype");
}
@font-face {
  font-family: "OstrichSansInlineregular";
  src: local("OstrichSansInlineregular"),
  url("/fonts/OstrichSansInlineregular.otf") format("truetype");
}
@font-face {
  font-family: "Outline";
  src: local("Outline"),
  url("/fonts/Outline.ttf") format("truetype");
}
@font-face {
  font-family: "PacificAgain";
  src: local("PacificAgain"),
  url("/fonts/PacificAgain.ttf") format("truetype");
}
@font-face {
  font-family: "Pumkinpie";
  src: local("Pumkinpie"),
  url("/fonts/Pumkinpie.ttf") format("truetype");
}
@font-face {
  font-family: "QwertyAbility";
  src: local("QwertyAbility"),
  url("/fonts/QwertyAbility.otf") format("truetype");
}
@font-face {
  font-family: "RusticPantry";
  src: local("RusticPantry"),
  url("/fonts/RusticPantry.ttf") format("truetype");
}
@font-face {
  font-family: "SalamanderScript";
  src: local("SalamanderScript"),
  url("/fonts/SalamanderScript.otf") format("truetype");
}
@font-face {
  font-family: "SimplicityDisplay";
  src: local("SimplicityDisplay"),
  url("/fonts/SimplicityDisplay.otf") format("truetype");
}
@font-face {
  font-family: "SimplicityDisplayRegular";
  src: local("SimplicityDisplayRegular"),
  url("/fonts/SimplicityDisplayRegular.ttf") format("truetype");
}
@font-face {
  font-family: "StayDreaming";
  src: local("StayDreaming"),
  url("/fonts/StayDreaming.otf") format("truetype");
}
@font-face {
  font-family: "SuperYeasty";
  src: local("SuperYeasty"),
  url("/fonts/SuperYeasty.otf") format("truetype");
}
@font-face {
  font-family: "TheKingOfRomance";
  src: local("TheKingOfRomance"),
  url("/fonts/TheKingOfRomance.ttf") format("truetype");
}
@font-face {
  font-family: "Vallentine";
  src: local("Vallentine"),
  url("/fonts/Vallentine.otf") format("truetype");
}
@font-face {
  font-family: "VampireCalligraphy";
  src: local("VampireCalligraphy"),
  url("/fonts/VampireCalligraphy.ttf") format("truetype");
}
@font-face {
  font-family: "VanillaCream";
  src: local("VanillaCream"),
  url("/fonts/VanillaCream.ttf") format("truetype");
}
@font-face {
  font-family: "Vendetta";
  src: local("Vendetta"),
  url("/fonts/Vendetta.otf") format("truetype");
}
@font-face {
  font-family: "VINTAGECOLLEGEDEPToutline";
  src: local("VINTAGECOLLEGEDEPToutline"),
  url("/fonts/VINTAGECOLLEGEDEPToutline.otf") format("truetype");
}
@font-face {
  font-family: "VINTAGECOLLEGEDEPTpure";
  src: local("VINTAGECOLLEGEDEPTpure"),
  url("/fonts/VINTAGECOLLEGEDEPTpure.otf") format("truetype");
}
@font-face {
  font-family: "Watermelon";
  src: local("Watermelon"),
  url("/fonts/Watermelon.ttf") format("truetype");
}
@font-face {
  font-family: "WatermelonScript";
  src: local("WatermelonScript"),
  url("/fonts/WatermelonScript.ttf") format("truetype");
}
@font-face {
  font-family: "Wedding";
  src: local("Wedding"),
  url("/fonts/Wedding.ttf") format("truetype");
}
@font-face {
  font-family: "WestwoodStudio";
  src: local("WestwoodStudio"),
  url("/fonts/WestwoodStudio.ttf") format("truetype");
}
@font-face {
  font-family: "YessyFontbySitujuh";
  src: local("YessyFontbySitujuh"),
  url("/fonts/YessyFontbySitujuh.otf") format("truetype");
}
